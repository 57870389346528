<template>
  <div>
    <el-container>
      <el-main style="background: #ffffff; height: 100%">
        <head-layout
          @head-save="trainingNoticeSaving()"
          @head-saveBack="headSubmit()"
          @head-cancel="headCancel"
          :head-btn-options="headBtnOptions"
          @head-sign="toSee()"
          head-title="培训通知"
        >
        </head-layout>
        <div style="padding: 12px">
          <el-form ref="form" :model="form" :rules="rules" label-width="150px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="培训主题:" prop="trainTheme">
                  <el-input
                    type="text"
                    v-model="form.trainTheme"
                    :disabled="
                      this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    :placeholder="'请输入培训主题'"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--培训时间 时间范围选择-->
                <el-form-item label="培训时间:" prop="trainTimeRange">
                  <el-date-picker
                    v-model="form.trainTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                    :disabled="
                      this.type != 'add' && this.type != 'curriculumCenter'
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="课程类型:" prop="courseType">
                  <el-radio-group
                    v-model="form.courseType"
                    :disabled="type != 'add' && type != 'curriculumCenter'"
                  >
                    <el-radio label="1">继续教育</el-radio>
                    <el-radio label="2">场景培训</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <!--培训计划 计划内 计划外，计划内支持选择-->
                <el-form-item label="年度培训计划:" prop="trainPlan">
                  <el-radio-group
                    v-model="form.trainPlan"
                    :disabled="
                      this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    @change="handleTrainPlanChange"
                  >
                    <el-radio :label="1">计划内</el-radio>
                    <el-radio :label="2">计划外</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="this.form.trainPlan == '1'"
                  label="计划培训名称:"
                  prop="trainingPlanId"
                >
                  <el-select
                    v-model="form.trainingPlanId"
                    placeholder="请选择计划培训名称"
                    :popper-append-to-body="false"
                    :disabled="
                      this.type != 'add' && this.type != 'curriculumCenter'
                    "
                  >
                    <el-option
                      v-for="item in trainingPlans"
                      :key="item.id"
                      :label="item.trainMessage"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-else></el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注:" prop="trainMessage">
                  <el-input
                    type="textarea"
                    v-model="form.trainMessage"
                    :disabled="
                      this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-tabs v-model="activeName">
              <el-tab-pane label="课程信息" name="courseInformation">
                <div>
                  <div class="formTopic">
                    <span style="font-size: 14px; margin-left: 6px">课程信息</span>
                  </div>
                  <onTheJobTraining
                    ref="onTheJobTraining"
                    :chooseACourseTask="chooseACourseTask"
                    :trainingTaskList="trainingTaskList"
                  ></onTheJobTraining>
                  <div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="参与培训人员" name="trainingPersonnel">
                <div style="margin-top: 12px">
                  <div class="formTopic">
                    <span style="font-size: 14px; margin-left: 6px">参与培训人员</span>
                    <el-button
                      type="primary"
                      size="mini"
                      style="height: 28px; margin-top: 6px"
                      @click="handleUserModeOpen"
                      v-if="type != 'view'"
                    >
                      新增
                    </el-button>
                  </div>
                  <grid-head-layout
                    ref="searchForm"
                    :search-columns="noticePersonColumns"
                    @grid-head-search="noticePersonSearch"
                    @grid-head-empty="noticePersonRest"
                  />

                  <el-table
                    :data="noticePersonTableData"
                    size="medium"
                    height="calc(100vh - 500px)"
                    style="width: 100%"
                  >
                    <el-table-column
                      type="index"
                      label="序号"
                      align="center"
                      width="80"
                    >
                    </el-table-column>
                    <el-table-column prop="userName" label="姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="deptName" label="组织" align="center">
                    </el-table-column>
                    <el-table-column prop="totalHours" label="总课时" align="center">
                    </el-table-column>
                    <el-table-column prop="standardsHours" label="达标课时" align="center">
                    </el-table-column>
                    <el-table-column prop="accomplishAccomplish" label="完成必修课时" align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="培训方式" align="center" :formatter="formatIsCompulsory">
                    </el-table-column>
                    <el-table-column prop="isAccomplish" label="是否完成" align="center" :formatter="completeOrNot">
                    </el-table-column>

                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="text"
                          v-if="scope.row.isAccomplish&&type != 'view'"
                          @click="viewDetails(scope.row)"
                        >编辑
                        </el-button>
                        <el-button
                          v-if="type != 'view'"
                          size="mini"
                          type="text"
                          @click="handleDelete(scope.row)"
                        >删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <CommonDialog v-if="deptShow" width="90%" dialogTitle="参加培训人员" @cancel="deptShow = false" @confirm="selectAllData">
      <userDepartmentSeparationDialog
        ref="userDepartmentSeparationDialog"
        v-if="deptShow"
        :disabled-list="disabledErpItemList"
      ></userDepartmentSeparationDialog>
    </CommonDialog>

    <CommonDialog v-if="showFileDialog" :showConfirmBtn="false" width="70%" dialogTitle="附件预览" @cancel="showFileDialog = false">
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
    </CommonDialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import userDepartmentSeparationDialog from "@/views/components/UserDeptDialog/userDepartmentSeparationDialog.vue";
import {
  trainingTaskDetail,
  trainingTaskSubmit,
  gettrainingPlanpage,
  trainingUpdateSign,
  trainingTaskRemove,
  removeUserByUserId,
} from "@/api/safe/index";
import website from "@/config/website";
import {dictionaryBiz} from "@/api/reportTasks";
import publicCurriculumPopUp
  from "@/views/business/safetyenment/train/trainingTaskList/components/publicCurriculumPopUp";
import {getDetailBySiteTrainingId} from "@/api/training/recording";
import {
  saveCorrespondingData,
  whetherToStartTraining,
} from "@/api/training/trainGroupScene";
import {dateFormat} from "@/util/date";
import {companyProjects} from "@/api/system/dept";
import onTheJobTraining from "@/views/business/knowledge/onTheJobTraining/addNew.vue";
import CommonDialog from "@/components/CommonDialog";
export default {
  components: {
    HeadLayout,
    CommonTree,
    GridLayout,
    userDepartmentSeparationDialog,
    publicCurriculumPopUp,
    onTheJobTraining,
    CommonDialog
  },
  data() {
    return {
      activeName: "courseInformation",//table选项卡切换
      trainingType: 1,
      readonly: false,
      tableData: [],
      deptShow: false,
      fileList: [],
      disabledErpItemList: [],
      trainingPlans: [], //培训计划
      courseTypeList: [], //课程类型
      levelEducationList: [], //级别教育
      courseDialog: false,
      courseType: "1",
      trainingTaskList: "trainingTaskListView", //传入课程的状态
      noticePersonPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      condition: "", //条件
      userId: "",
      deptId: "",
      submitLoading: false,
      showFileDialog: false,
      fileUrl: "",
      type: "",
      trainTimeRange: "",
      startTrainingButton: false, //开始培训按钮
      // trainTimeRange: [new Date(), this.getCurrentTime()],
      dicData: [{dictKey: "1", dictValue: "线上"}, {dictKey: "2", dictValue: "线下"}],
      completedTrainingOrNot: [],

      form: {
        levelEducation: "",
        courseType: "2",
        id: "",
        trainTheme: "",
        deptRelationId: "",
        trainTimeRange: "",
        roleRelationId: "",
        trainStart: "",
        trainEnd: "",
        trainPlan: 2, //培训计划1:计划内 2:计划外
        trainingPlanId: "",
        fileId: "",
        knowledgeFileId: "",
        sysAttachList: [],
        trainWay: 2, //培训方式1:线上 2:线下
        examPaperId: "",
        paperName: "",
        trainMessage: "",
        groupCode: "", //机构分组
        roleIds: "",
        status: 1, //状态草稿,
        userRelationList: [],
      },
      rules: {
        trainTheme: [
          {required: true, message: "请输入培训主题", trigger: "change"},
        ],
        trainTimeRange: [
          {required: true, message: "请选择培训日期", trigger: "change"},
        ],
        trainingPlanId: [
          {required: true, message: "请选择培训计划", trigger: "change"},
        ],
      },
      noticePersonTableData: [],
      allNoticePersonTableData: [],
      noticePersonTableLoading: false,
    };
  },
  computed: {
    ...mapGetters(["tag", "roleData", "userInfo", "themeColor"]),
    headBtnOptions() {
      let buttonBtn = [];
      if (
        ["add", "edit", "curriculumCenter"].includes(this.type) ||
        ["supplementaryDutyPersonnel"].includes(this.type)
      ) {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
        buttonBtn.push({
          label: "保存并返回",
          emit: "head-saveBack",
          type: "button",
          icon: "",
          btnOptType: "saveBack",
        });
      }
      if (["view"].includes(this.type) && this.condition == 1) {
        buttonBtn.push({
          label: "签收",
          emit: "head-sign",
          type: "button",
          icon: "",
          btnOptType: "sign",
        });
      }
      buttonBtn.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        icon: "",
        btnOptType: "cancel",
      });
      return buttonBtn;
    },
    noticePersonColumns() {
      return [
        {
          placeholder: "请输入姓名",
          type: "input",
          prop: "userName",
          span: 4,
        },
        {
          placeholder: "请输入组织名称",
          type: "input",
          prop: "deptName",
          span: 4,
        },
      ];
    },
    noticePersonTableOptions() {
      return {
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            align: "center",
            overHidden: true,
          },

          {
            label: "组织",
            prop: "deptName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    tableOptions() {
      return {
        selection: false,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "课程名称",
            prop: "courseName",
            align: "left",
            overHidden: true,
          },

          {
            label: "培训类型",
            prop: "trainingScenarios",
            width: 200,
            align: "center",
            overHidden: true,
          },

          {
            label: "课程类型",
            prop: "courseType",
            align: "center",
            width: 150,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=course_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            overHidden: true,
          },
        ],
      };
    },
  },
  created() {
    gettrainingPlanpage(1, 999).then((res) => {
      const data = res.data.data;
      this.trainingPlans = data.records;
    });
    dictionaryBiz("course_type").then((res) => {
      this.courseTypeList = res.data.data;
    });
    dictionaryBiz("education_is_accomplish").then((res) => {
      this.completedTrainingOrNot = res.data.data;
    });
    let {id, type, condition, userId, deptId, row} = this.$route.query;
    console.log(id, "627");
    if (id != undefined) {
      this.id = id;
    } else {
      this.id = sessionStorage.getItem("taskId");
    }
    if (type != undefined) {
      this.type = type;
    } else {
      let startDate = dateFormat(new Date(), "yyyy-MM-dd");
      let endDate = dateFormat(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), "yyyy-MM-dd");
      this.form.trainTimeRange = [startDate, endDate]
      this.type = sessionStorage.getItem("taskType");
    }
    if (["curriculumCenter"].includes(this.type)) {
      this.readonly = false;
      let curriculumCenterRow = JSON.parse(row);
      let startDate = dateFormat(new Date(), "yyyy-MM-dd");
      let endDate = dateFormat(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), "yyyy-MM-dd");
      this.form.trainTimeRange = [startDate, endDate]
      this.form.courseType = curriculumCenterRow.courseType;
      this.curriculumSubmit(curriculumCenterRow);
    } else if (["supplementaryDutyPersonnel"].includes(this.type)) {
      this.readonly = true;
      this.init(this.id);
      // let curriculumCenterRow = JSON.parse(row);
      // this.curriculumSubmit(curriculumCenterRow)
    } else {
      if (["view"].includes(this.type)) {
        this.condition = condition;
        this.userId = userId;
        this.deptId = deptId;
        this.readonly = true;
      } else {
        this.readonly = false;
      }
      if (["edit", "view"].includes(this.type)) {
        this.init(this.id);
      }
    }
  },
  methods: {
    curriculumSubmit(row) {
      //点击确认后清空上一条数据
      if (typeof this.tableData != "undefined" && this.tableData.length == 1) {
        this.empty();
      }
      this.$nextTick(() => {
        this.$refs.onTheJobTraining.initDate(row.id);
        let cc = [];
        //将id制空方便保存数据
        row.trainingNoticeId = row.id;
        row.id = "";
        cc.push(row);
        this.tableData = cc;
        this.courseDialog = false;
      });
    },
    empty() {
      this.toDel(this.tableData[0], 0);
    },
    chooseACourseTask() {
      if (!this.form.courseType) {
        return this.$message({
          type: "warning",
          message: "请先选择课程类型",
        });
      }
      this.courseDialog = true;
      console.log("this.fromthis.fromthis.from", this.form);
      this.courseType = this.form.courseType;
    },
    formatIsCompulsory(row, column) {
      const value = row[column.property];
      const dict = this.dicData.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    completeOrNot(row, column) {
      const value = row[column.property];
      const dict = this.completedTrainingOrNot.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    handleAvatarSuccess(res, file, fileList) {
      const parts = file.response.data.originalName.split("."); // 使用 . 分割字符串
      const extension = parts[parts.length - 1]; // 获取最后一个部分作为后缀名
      this.fileList.push({
        extension: extension,
        id: file.response.data.attachId,
        link: file.response.data.link,
        originalName: file.response.data.originalName,
        createTime: this.sinoma.toDate(
          new Date().getTime(),
          "yyyy-MM-dd HH:mm:ss"
        ),
      });
      this.$message({
        type: "success",
        message: "上传成功！",
      });
    },
    handleClick(file) {
      let a = document.createElement("a");
      a.href = file.link;
      a.click();
    },
    handleDeleteFile(file) {
      this.$confirm("确定将数据删除？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.fileList = this.fileList.filter((item) => {
          if (item.id != file.id) {
            return item;
          }
        });
      });
    },
    handleViewFile(file) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(file.link));
      this.showFileDialog = true;
    },
    //获取一小时之前的日期
    getCurrentTime() {
      return new Date(new Date().getTime() + 1 * 60 * 60 * 1000); // 前一个小时  当前时间
    },
    rowDelete(index) {
      this.tableData.splice(index, 1);
      this.tableData = [];
    },
    toDel(row, index) {
      this.$refs.onTheJobTraining.empty();
      this.rowDelete(index);
      if (row.id) {
        trainingTaskRemove(row.id);
      }
    },
    //前端时间格式的转换
    timeChange(UTCDateString) {
      if (!UTCDateString) {
        return "-";
      }

      function formatFunc(str) {
        return str > 9 ? str : "0" + str;
      }

      var date2 = new Date(UTCDateString);
      var year = date2.getFullYear();
      var mon = formatFunc(date2.getMonth() + 1);
      var day = formatFunc(date2.getDate());
      var hour = date2.getHours();
      //var noon = hour >= 12 ? 'PM' : 'AM'; // 判断是上午还是下午
      hour = hour >= 12 ? hour : hour; // 24小时制
      //hour = hour >= 12 ? hour - 12 : hour; // 12小时制
      hour = formatFunc(hour);
      var min = formatFunc(date2.getMinutes());
      var sec = formatFunc(date2.getSeconds());
      var dateStr =
        year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + sec;
      return dateStr;
    },
    trainingNoticeSaving(res, isTraining) {
      const _that = this;
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          this.startTrainingButton = true;
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.form.fileId = fileIds;
          _that.$refs.searchForm.searchForm.userName = "";
          _that.$refs.searchForm.searchForm.deptName = "";
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.status = 2;
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.whetherToStartTraining = "startTraining";
          _that.form.trainingStatus =1;
          _that.form.storageMode ="additionalRecording";
          _that.form.educations = _that.form.userRelationList;
          trainingTaskSubmit(_that.form).then((res) => {
            if (res.data.code == 200) {
              _that.form.id = res.data.data.id;
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
              this.init(_that.form.id, "startTraining");
              sessionStorage.setItem("taskId", res.data.data.id);
              sessionStorage.setItem("taskType", "edit");
            }
            this.startTrainingButton = false;
          });
          this.$loading().close();
        }
      });
    },
    headSave(res, isTraining) {
      const _that = this;
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.form.fileId = fileIds;
          _that.$refs.searchForm.searchForm.userName = "";
          _that.$refs.searchForm.searchForm.deptName = "";
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.status = 2;
          _that.form.whetherToStartTraining = "startTraining";
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.educations = _that.form.userRelationList;
          _that.form.storageMode ="additionalRecording";
          if (res == "startTraining") {
            _that.form.whetherToStartTraining = "startTraining";
          }
          trainingTaskSubmit(_that.form)
            .then((res) => {
              if (res.data.code == 200) {
                _that.form = res.data.data;
                this.$message({
                  type: "success",
                  message: this.$t("cip.cmn.msg.success.operateSuccess"),
                });
                this.init(_that.form.id, "startTraining");
                sessionStorage.setItem("taskId", res.data.data.id);
                sessionStorage.setItem("taskType", "edit");
              }
            })
          this.$route.query.type = "edit";
          this.type = "edit";
          this.$loading().close();
        }
      });
    },
    /**
     * 跳转我的培训界面
     * @param row
     */
    viewDetails(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/view",
        query: {
          id: row.id,
          type: "additionalRecording"
        },
      });
    },
    handleDelete(row) {
      console.log(row, "row");
      this.noticePersonTableData = this.noticePersonTableData.filter((item) => {
        if (item.userId != row.userId || item.deptId != row.deptId) {
          return item;
        }
      });
      this.allNoticePersonTableData = this.noticePersonTableData;
      if (row.id) {
        removeUserByUserId({
          id: row.id,
          taskId: this.form.id,
          userId: row.userId,
        });
      }
    },

    headSubmit() {
      let _that = this;
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          //培训时间处理
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.fileId = fileIds;
          _that.form.status = 2;
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.educations = _that.form.userRelationList;
          _that.form.trainingStatus =1;
          _that.form.storageMode ="additionalRecording";
          trainingTaskSubmit(_that.form).then((res) => {
            _that.form = res.data.data;
            this.$router.$avueRouter.closeTag();
            this.$router.back();
            this.$loading().close();
          });
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    init(param, res) {
      trainingTaskDetail({id: param}).then((res) => {
        const data = res.data.data;
        console.log(data, "data.trainingType");
        this.trainingType = data.trainingType;
        this.noticePersonTableData = data.educations;
        this.allNoticePersonTableData = data.educations;
        this.disabledErpItemList = this.noticePersonTableData.map(
          (e) => e.userId + "" + e.firm
        );
        this.fileList = data.attachList;
        this.tableData = data.trainingTrainingCourse;
        if (data.trainingTrainingCourse.length > 0) {
          this.$refs.onTheJobTraining.initDate(
            data.trainingTrainingCourse[0].trainingNoticeId,
            param, "task"
          );
        }
        this.form = data;
        console.log(this.form, '1080')
        this.form.trainTimeRange = [
          data.trainStart,
          data.trainEnd,
        ];
        // this.form.trainTimeRange = ['2021','2024']
      });
    },
    //签收
    toSee() {
      if (this.form.id) {
        trainingUpdateSign(this.form.id, this.deptId, this.userId).then(
          (res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "签收成功！",
              });
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          }
        );
      }
    },
    noticePersonSearch(searchparams) {
      this.noticePersonTableData = this.allNoticePersonTableData;
      if (searchparams.userName || searchparams.deptName) {
        this.noticePersonTableData = this.noticePersonTableData.filter(
          (item) => {
            if (searchparams.userName || searchparams.deptName) {
              if (
                item.userName.includes(searchparams.userName) ||
                item.deptName.includes(searchparams.deptName)
              ) {
                return item;
              }
            }
          }
        );
      }
    },
    noticePersonRest() {
      this.noticePersonTableData = this.allNoticePersonTableData;
    },
    handleUserModeOpen() {
      console.log(this.noticePersonTableData)
      this.disabledErpItemList = this.noticePersonTableData.map(
        (e) => e.userId + "" + e.deptId
      );
      console.log("this.disabledErpItemList",this.disabledErpItemList)
      this.deptShow = true;
    },
    selectAllData(data) {
      var confirmTheSubmission = this.$refs.userDepartmentSeparationDialog.confirmTheSubmission();
      //表格里选择的人员为线上培训的数据
      confirmTheSubmission.forEach((item) => {
        this.noticePersonTableData.push({
          userName: item.realName,
          userId: item.userId,
          deptId: item.deptId,
          deptName: item.deptName,
          type: 2,
          storageMode:"additionalRecording"
        });
      });
      this.allNoticePersonTableData = this.noticePersonTableData;
      this.deptShow = false;
    },
    handleTrainPlanChange() {
      ["trainTimeRange", "trainTheme", "trainingPlanId"].forEach((prop) => {
        this.clearFormFieldError(prop);
      });
    },
    clearFormFieldError(fieldProp) {
      const field = this.$refs.form.fields.find(
        (field) => field.prop === fieldProp
      );
      if (field) {
        field.error = "";
        field.validateStatus = "";
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.common_tree_handle {
  min-width: 188px;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-form-item__content {
  .el-tag {
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    margin-bottom: 5px;

    .el-icon-close {
      font-size: 16px;
    }
  }
}

.left-content {
  box-sizing: border-box;
  background: #fff;

  .content {
    display: flex;
    align-items: center;

    .img-content {
      width: 188px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;

      img {
        width: 188px;
        height: 100px;
      }

      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }

      .file-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }
}

.formTopicCourse {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //margin-bottom: 72px;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  height: 338px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.deptBox .unitTitle {
  font-size: 13px;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

::v-deep .el-checkbox-group {
  display: flex;
  overflow-x: auto;
}

::v-deep .el-checkbox {
  margin-right: 5px !important;
}

.paperDialog ::v-deep .el-dialog__body {
  padding: 10px;
}

.knowledge ::v-deep .el-dialog__body {
  padding-top: 20px !important;
}

.paperDialog ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: none !important;
}

.knowledge ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: block !important;
}

.paperDialog ::v-deep #gridLayout {
  height: calc(100% - 56px);
}

.paperDialog ::v-deep .el-tree {
  height: calc(100% - 56px) !important;
}

.paperDialog ::v-deep .el-table {
  height: 392px !important;
}

.paperDialog ::v-deep .el-table__body-wrapper {
  max-height: 358px !important;
  overflow-y: auto;
}

.paperDialog ::v-deep .el-container {
  height: 500px !important;
}

.dialogBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stareBox {
  position: absolute;
  color: #f56c6c;
  left: -134px;
}

.fileBigBox {
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  overflow: auto;
  border-top: 1px solid #bbbbbb;
  margin-top: 12px;
}

.fileBox {
  width: 100%;
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #bbbbbb;
}

.fileBox div {
  width: 25%;
  text-align: center;
  font-size: 14px;
  border-right: 1px solid #bbbbbb;
}

.fileName {
  border-left: 1px solid #bbbbbb;
  padding: 0 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fileName span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fileType {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 6px;
}

//::v-deep .fileName .el-link--inner {
//  width: 100%;
//  text-overflow: ellipsis;
//  overflow: hidden;
//  white-space: nowrap;
//}

.fileOperate {
  display: flex;
  justify-content: center;
}

.fileOperate span {
  cursor: pointer;
  margin: 0 6px;
}

::v-deep .UserDeptMultipleDialog .avue-crud .el-table {
  height: calc(100vh - 384px) !important;
  max-height: calc(100vh - 384px) !important;
}

::v-deep .UserDeptMultipleDialog .avue-tree__content {
  height: calc(100vh - 350px);
}


::v-deep .UserDeptMultipleDialog .el-tree {
  height: calc(100vh - 374px) !important;
}

::v-deep .courseSelection .avue-crud .el-table {
  height: calc(100vh - 460px) !important;
  max-height: calc(100vh - 460px) !important;
}

::v-deep .courseSelection .el-tree {
  height: calc(100vh - 460px) !important;
}
</style>
